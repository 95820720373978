.sd-rating {
  overflow-x: auto;
  min-height: calcSize(8);

  fieldset {
    display: flex;
    border: none;
    padding: 0 0 calcSize(2) 0;
    flex-wrap: nowrap;
    gap: calcSize(1);
    margin-inline-start: 0;
    align-items: center;
  }

  &.sd-rating--wrappable {
    fieldset {
      flex-wrap: wrap;
    }
  }
}

.sd-rating__item {
  position: relative;
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  border-radius: calcSize(12.5);
  white-space: nowrap;
  padding: calcSize(1.25);
  box-sizing: border-box;
  min-width: calcSize(6);
  text-align: center;
  border: 2px solid $background;
  color: $foreground;
  font-size: calcSize(2);
}

legend+.sd-rating__item,
legend+sv-ng-rating-item+.sd-rating__item {
  margin-inline-start: 2px;
}

.sd-rating__item--error {
  background-color: $red-light;
  box-shadow: 0px 1px 2px transparent;
  border-color: transparent;
}

.sd-rating__item.sd-rating__item--disabled {
  color: $foreground-disabled-for-editors;
}

.sd-rating__item.sd-rating__item--selected.sd-rating__item--disabled {
  color: $primary-foreground-disabled;
}

.sd-rating__item--allowhover:hover,
.sd-rating__item:focus-within {
  border-color: $primary;
}

.sd-rating__item--selected {
  background-color: $primary;
  border-color: $primary;
  color: $primary-foreground;
  font-weight: 600;
}

.sd-rating__item--selected:focus-within {
  box-shadow: inset 0 0 0 2px $background;
}

.sd-rating__item-text.sd-rating__item-text {
  font-size: calcSize(2);
  line-height: calcSize(3);
  min-width: calcSize(3);
  display: inline-block;

  &.sd-rating__min-text,
  &.sd-rating__max-text {
    margin-top: calcSize(1.25);
    margin-bottom: calcSize(1.25);
    border: 2px solid $background;
    color: $foreground-light;
  }

  &.sd-rating__min-text {
    margin-right: calcSize(1);
    // margin-left: calcSize(2);
    border-left: 0px;
  }

  &.sd-rating__max-text {
    margin-right: calcSize(2);
    margin-left: calcSize(1);
  }

  .sv-string-editor {
    white-space: nowrap;
  }
}